<template>
    <div class="retro-test">
        <div class="switch-wrapper">
            <t-switch-btn
                    :switches="menu"
                    :active="activeTab" @click="switcher" active-color="#FFAB2D"/>
            <span><b>{{retroData.start_tf
                ? `Расчет для ${retroData.start_tf.join()}`
                : "Подождите идет расчет"}}</b></span>
            <rnd-btn @click="onScreenShot($refs.screenShot)" :class="[{}, 'absolute']">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2222 4.5H5.77778C4.79594 4.5 4 5.29594 4 6.27778V18.7222C4 19.7041 4.79594 20.5 5.77778 20.5H18.2222C19.2041 20.5 20 19.7041 20 18.7222V6.27778C20 5.29594 19.2041 4.5 18.2222 4.5Z"
                      stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M20.0001 15.1666L15.5556 10.7222L5.77783 20.4999" stroke="currentColor"
                      stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.889 10.7223C9.62538 10.7223 10.2223 10.1254 10.2223 9.389C10.2223 8.65262 9.62538 8.05566 8.889 8.05566C8.15262 8.05566 7.55566 8.65262 7.55566 9.389C7.55566 10.1254 8.15262 10.7223 8.889 10.7223Z"
                      stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </rnd-btn>
        </div>
        <template v-if="activeTab.match('details')">
            <div class="switch-wrapper switch-wrapper_second">
                <t-switch-btn
                        :switches="detailsMenu"
                        :active="activeDetailsTab" @click="detailsSwitcher" active-color="#FFAB2D"/>
                <div>
                  <p v-if="activeSettings">
                    <span v-for="el in Object.keys(activeSettings)" :key="el" :class="activeSettings[el].color" style="margin-right: 20px">{{`${el!=="x" ? el.toUpperCase()+':' : ''} ${activeSettings[el].value}`}}</span>
                  </p>
                </div>
            </div>
        </template>
        <div class="screen-shot-wrapper">
          <section ref="screenShot" v-if="activeTab.match('main')" class="content">
            <div v-if="retroData.start_tf" class="settings" style="min-width: 310px">
                <table>
                    <caption><b>Исходные данные</b> (настройки)</caption>
                    <tbody>
                    <tr>
                        <td>Сумма:</td>
                        <td>$ <input type="text" v-model="settings.amount">
                        </td>
                    </tr>
                    <tr class="blue-color">
                        <td>Плечо:</td>
                        <td>x <input type="text" v-model="settings.leverage">
                        </td>
                    </tr>
                    <tr>
                        <td>LIMIT</td>
                        <td><input type="text" v-model="settings.limit"></td>
                    </tr>
                    <tr class="orange-color">
                        <td>HEDGE</td>
                        <td><input type="text" v-model="settings.hedge"></td>
                    </tr>
                    <tr class="red-color">
                        <td>STOP1</td>
                        <td><input type="text" v-model="settings.stop1"></td>
                    </tr>
                    <tr class="red-color">
                        <td>STOP2</td>
                        <td><input type="text" v-model="settings.stop2"></td>
                    </tr>
                    <tr class="green-color">
                        <td>TAKE1</td>
                        <td><input type="text" v-model="settings.take1"></td>
                    </tr>
                    <tr class="green-color">
                        <td>TAKE2</td>
                        <td><input type="text" v-model="settings.take2"></td>
                    </tr>
                    </tbody>
                </table>
                <menu-button :menu-list="offsetList.filter(el=>el.onPage.includes('retro-test'))" :active="activeOffset" @switch="offsetSwitch" is-changeable-header />
                <t-btn style="width: 100%" @click="$emit('save', settings)">Сохранить настройки
                </t-btn>
                <div class="files-loading">
                    <a :href="'/'+btn" download v-for="btn in retroData.files" :key="btn">{{normalizeBtnName(btn)}}</a>
                </div>
            </div>
            <div class="content-main">
              <table v-for="table in mainTables" :key="table.id">
                <thead>
                  <th :class="['white', table.bgColor]" colspan="3">{{table.title}}</th>
                </thead>
                <tbody>
                  <tr
                      v-for="(row, index) in retroData[table.id]"
                      :key="table.id+index"
                      :class="[{'no-hover': isRowEmpty(row)}]"
                  >
                    <template v-for="(data, ndx) of Object.keys(row)">
                      <td
                          :class="[
                              {'no-border': isRowEmpty(row)},
                              row[data]?.value_color ? row[data].value_color : '',
                              row[data]?.background_color ? row[data].background_color : '',
                              {'text-left': row[data]?.type.match('name')},
                              ]"
                          :key="table.id+index+data+ndx"
                          v-if="isRowEmpty(row) || row[data] !== null"
                          :colspan="mainColspan(row, data)"
                      >
                        {{returnMainValue(row, data)}}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section v-if="activeTab.match('details')" class="content details">
            <table ref="screenShot">
              <thead>
                <tr>
                  <th v-for="header in headersDetails[activeDetailsTab]" :class="['white', header.class]" :key="header.value">{{header.title}}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="activeDetailsTab.toLowerCase().includes('pnl') && pnl">
                  <tr v-for="row in pnl" :key="row.symbol">
                    <td
                        v-for="header in headersDetails[activeDetailsTab]"
                        :key="row.symbol+header.value"
                        :class="[
                            header.class.includes('range') ? header.class : '',
                            header.value==='symbol' ? ['BTCUSDT', 'ETHUSDT', 'Total'].includes(row[header.value]) ? 'water-bg' : 'darkblue-bg'
                            : row[header.value] > 0 ? 'green' : 'red',
                            ]"
                    >
                      {{returnValue(header.value, row[header.value])}}
                    </td>
                  </tr>
                </template>
                <template v-if="activeDetailsTab.toLowerCase().includes('deals') && deals">
                  <tr v-for="row in deals" :key="row.symbol">
                    <td
                        v-for="header in headersDetails[activeDetailsTab]"
                        :key="row.symbol+header.value"
                        :class="[
                            header.class.includes('range') ? header.class : '',
                            header.value==='symbol' ? ['BTCUSDT', 'ETHUSDT', 'Total'].includes(row[header.value]) ? 'water-bg' : 'darkblue-bg'
                            : header.value.includes('loss') ? 'red'
                            : header.value.includes('prof') ? 'green' : '',
                            ]"
                        :style="header.value!=='symbol' ? 'text-align: center' : ''"
                    >
                      {{returnValue(header.value, row[header.value])}}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
        </section>
        </div>
    </div>
</template>

<script>
    import TBtn from "../Blocks/TBtn";
    import TSwitchBtn from "../Blocks/TSwitchBtn";
    import domtoimage from "dom-to-image-more";
    import RndBtn from "@/components/Blocks/RndBtn/RndBtn";
    import MenuButton from "@/components/Blocks/MenuButton/MenuButton";

    export default {
        name: "RetroTestPage",
        components: {MenuButton, RndBtn, TSwitchBtn, TBtn},
        props: {
            retroSettings: Object,
            retroData: Object,
            retroDeals: Object,
            retroPnl: Object,
            offsetList: Array,
            activeOffset: String,
        },
        data: () => ({
            settings: {},
            menu: [
                {value: "main", title: "Общий"},
                {value: "details", title: "Подробно"}
            ],
            detailsMenu: [
                {value: "retroPnl", title: "СВОДКА PNL"},
                {value: "retroDeals", title: "СВОДКА DEALS"},
            ],

            detail: [
            "total",
            "long",
            "short",
          ], // files titling

            activeTab: "main",
            activeDetailsTab: "retroPnl",
            mainTables: [
              {
                title: "СВОДКА ALL SIGNALS",
                id: "all_signals",
                bgColor: "blue-bg",
              },
              {
                title: "СВОДКА LONGS",
                id: "longs",
                bgColor: "middlegreen-bg",
              },
              {
                title: "СВОДКА SHORTS",
                id: "shorts",
                bgColor: "lightred-bg",
              },
            ],
            headersDetails: {
              retroPnl: [
                {
                  title: 'PARES',
                  value: 'symbol',
                  class: 'water-bg',
                },
                {
                  title: 'PNL ALL SIGN',
                  value: 'pnl_all_sign',
                  class: 'blue-bg',
                },
                {
                  title: 'PNL LONG',
                  value: 'pnl_long',
                  class: 'middlegreen-bg',
                },
                {
                  title: 'PNL SHORT',
                  value: 'pnl_short',
                  class: 'lightred-bg',
                },
                {
                  title: '',
                  value: 'range',
                  class: 'range',
                },
                {
                  title: 'PNL ZE ALL',
                  value: 'pnl_ze_all',
                  class: 'purple-bg',
                },
                {
                  title: 'PNL ZE LONG',
                  value: 'pnl_ze_long',
                  class: 'middlegreen-bg',
                },
                {
                  title: 'PNL ZE SHORT',
                  value: 'pnl_ze_short',
                  class: 'lightred-bg',
                },
              ],
              retroDeals: [
                {
                  title: 'PARES',
                  value: 'symbol',
                  class: 'water-bg',
                },
                {
                  title: 'DEALS ALL SIGN',
                  value: 'deals_all_sign',
                  class: 'blue-bg',
                },
                {
                  title: 'LOSS',
                  value: 'deals_all_loss',
                  class: 'red-bg',
                },
                {
                  title: 'PROFIT',
                  value: 'deals_all_prof',
                  class: 'middlegreen-bg',
                },
                {
                  title: '',
                  value: 'range1',
                  class: 'range',
                },
                {
                  title: 'DEALS LONG',
                  value: 'deals_long',
                  class: 'lightgreen-bg',
                },
                {
                  title: 'LOSS',
                  value: 'deals_long_loss',
                  class: 'red-bg',
                },
                {
                  title: 'PROFIT',
                  value: 'deals_long_prof',
                  class: 'middlegreen-bg',
                },
                {
                  title: '',
                  value: 'range2',
                  class: 'range',
                },
                {
                  title: 'DEALS SHORT',
                  value: 'deals_short',
                  class: 'lightred-bg',
                },
                {
                  title: 'LOSS',
                  value: 'deals_short_loss',
                  class: 'red-bg',
                },
                {
                  title: 'PROFIT',
                  value: 'deals_short_prof',
                  class: 'middlegreen-bg',
                },
              ],
            }
        }),
        computed: {
          activeSettings() {
            const colors = {
              stop: 'red',
              take: 'green',
              x: 'blue',
            }
            switch (this.activeDetailsTab) {
              case 'retroPnl':
                return this.retroPnl?.settings?.reduce((acc, el) => {
                  return Object.entries(el).reduce((c, [k, v]) => {
                    c[k ? k : "x"] = {
                      value: v,
                      color: colors[k ? k.toLowerCase() : "x"]
                    }
                    return c
                  }, {})
                }, {}) || null
              case 'retroDeals':
                return this.retroDeals?.settings?.reduce((acc, el) => {
                  return Object.entries(el).reduce((c, [k, v]) => {
                    c[k ? k : "x"] = {
                      value: v,
                      color: colors[k ? k.toLowerCase() : "x"]
                    }
                    return c
                  }, {})
                }, {}) || null
            }
            return null
          },
          pnl() {
            return this.retroPnl?.data
          },
          deals() {
            return this.retroDeals?.data
          },
        },
        watch: {
            retroSettings(newVal) {
                this.settings = {...newVal}
            }
        },
        methods: {
          isRowEmpty(row) {
            return Object.values(row).filter(el=>el!==null).length === 0
          },
          normalizeBtnName(btn) {
            return `${btn.toString().split('_').find(
                          el => this.detail.includes(el))}.${btn.toString().split('.').pop()}`
          },
          offsetSwitch(offset) {
            this.$emit('switch-offset', { offset: offset })
          },
          switcher(data) {
              this.activeTab = data
              this.$emit('changeTab', data)
          },
          detailsSwitcher(data) {
              this.activeDetailsTab = data
              this.$emit('changeTab', data)
          },

// -------- main page rendering
          mainColspan(row, data) {
            const cell = Object.entries(row).filter(([k, v]) => v!==null && k!=='cell0').flat()
            if (cell.length === 2) {
              const [k, v] = cell
              if (k === data && v !== null) return 2
            }
            return 1
          },
          returnMainValue(row, cell) {
            if (row[cell]?.type) {
              switch (row[cell].type) {
                case 'percent':
                  return `${row[cell].value}%`
                case 'amount':
                  return row[cell].value > 0
                      ? `$ ${row[cell].value.toLocaleString("ru-RU", {useGrouping:true})}`
                      : `-$ ${row[cell].value.toLocaleString("ru-RU", {useGrouping:true}).replace('-', '')}`
                default :
                  return row[cell].value.toLocaleString("ru-RU", {useGrouping:true})
              }
            }
            return row[cell]
          },
          returnMainValueColor(row, data) {
            if (['percent'].includes(data))
              return row[data] === 0 ? '' : row[data] > 50 ? 'green' : row[data] < 50 ? 'red' : 'gold'
            if (['amount'].includes(data))
              return row[data] > 0 ? 'green' : row[data] < 0 ? 'red' : 'gold'
          },
// --------

            returnValue(title, value) {
                switch (title) {
                    case 'symbol':
                      return value.toString().replace('USDT', '')
                    case 'pnl_all_sign':
                    case 'pnl_long':
                    case 'pnl_short':
                    case 'pnl_ze_all':
                    case 'pnl_ze_long':
                    case 'pnl_ze_short':
                      return value > 0
                          ? `$ ${value.toLocaleString("ru-RU",{useGrouping:true})}`
                          : `-$ ${value.toLocaleString("ru-RU",{useGrouping:true}).replace('-', '')}`
                    case 'deals_all_loss':
                    case 'deals_all_prof':
                    case 'deals_long_loss':
                    case 'deals_long_prof':
                    case 'deals_short_loss':
                    case 'deals_short_prof':
                      return `${value.toLocaleString("ru-RU",{useGrouping:true})}%`
                    default : return value
                }
            },
            async onScreenShot(screenShotArea) {
              const height = screenShotArea.offsetHeight;
              const width = screenShotArea.offsetWidth;
              await domtoimage.toJpeg(screenShotArea, {quality: 0.9, height: height, width: width, style: {'overflow': 'hidden'}})
                  .then((dataUrl) => {
                    this.doingScreenshot = false
                    const link = document.createElement('a');
                    link.download = 'screenshot.jpeg';
                    link.href = dataUrl;
                    link.click();
                  })
            },
        }
    }
</script>

<style scoped lang="scss">
    .retro-test {
        margin-top: 105px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        & .switch-wrapper {
            width: 100%;
            flex: 0 0 100%;
            display: flex;
            gap: 20px;
            align-items: center;
        }

        & p {
            margin: 0;
        }

        & .content {
            display: flex;
            column-gap: 50px;
          &::-webkit-scrollbar {
            width: 0;
          }

          &-main {
            display: flex;
            column-gap: 40px;
            align-items: flex-start;
          }

          & .settings {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            &::v-deep .button__menu__icon-btn {
              justify-content: center;
            }
            &::v-deep .button__menu__icon-btn__menu {
              width: 100%;
            }

            & tr, & td {
              &.blue-color {
                color: $blue-middle;
              }

              &.orange-color {
                color: #FFAB2D;
              }

              &.red-color {
                color: $red-middle;
              }

              &.green-color {
                color: $green;
              }
            }
          }

          &.details {
              position: relative;
              max-height: calc(100vh - 250px);
              overflow-y: auto;
              border-top-right-radius: 15px;
              border-top-left-radius: 15px;
            & td {
              &:first-child {
                text-align: left;
              }
              text-align: right;
            }
          }

          & table {
              box-sizing: border-box;
              border-spacing: 0;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: .0125em;

              flex: 1 1 auto;

              & thead th, & caption {
                  padding: 10px 5px;
                  border: 2px solid #5b5c67;
                  box-sizing: border-box;
                  border-top-left-radius: 15px;
                  border-top-right-radius: 15px;
                  &:first-child {
                      border-top-left-radius: 15px;
                      overflow: hidden;
                  }

                  &:last-child {
                      border-top-right-radius: 15px;
                      overflow: hidden;
                  }
              }

              & thead {
                  & tr {
                      position: sticky;
                      top: 0;
                      z-index: 100;
                      & th {
                          border-right: none;
                          &:last-child {
                              border-right: 2px solid #5b5c67;
                          }
                      }
                  }
              }

              & tbody {

                  & td, & th {
                      padding: 5px 5px;
                      border: 2px solid #5b5c67;
                      border-right: none;
                      border-bottom: none;
                      white-space: nowrap;

                      & input {
                          background: transparent;
                          color: inherit;
                          max-width: 40px;
                      }
                  }

                  & th, & td {
                      text-align: center;
                      box-sizing: border-box;
                      transition: all .3s;
                      position: relative;
                      z-index: 1;
                      height: 26px;

                      &:before {
                          content: '';
                          opacity: .5;
                          position: absolute;
                          left: 0;
                          right: 0;
                          top: 0;
                          bottom: 0;
                          z-index: -1;
                      }

                      &:last-child {
                          border-right: 2px solid #5b5c67;
                      }
                  }

                  & tr {
                      &:first-child {
                          & td, & th {
                              border-top: none;
                          }
                      }

                      &:nth-child(even) {
                        & td, & th {
                          &:not(.range) {
                            background: #222329;
                          }
                        }
                      }

                      &:last-child {
                          & td, & th {
                              border-bottom: 2px solid #5b5c67;

                              &:first-child {
                                  border-bottom-left-radius: 15px;
                                  overflow: hidden;
                              }

                              &:last-child {
                                  border-bottom-right-radius: 15px;
                                  overflow: hidden;
                              }
                          }
                      }

                      &:hover {
                          & td, & th {
                            &:not(.range) {
                              background-color: #41424b;
                            }
                          }
                      }

                      &.no-hover {
                        & td, & th {
                          background-color: transparent!important;
                        }
                      }

                      &.total {
                          & th, & td {
                              background-color: #010035;
                              &:after {
                                  background-color: #010035;
                              }
                          }
                      }

                    & th, & td {
                        &.no-border {
                          border-left-color: transparent;
                          border-right-color: transparent;
                        }
                      }
                  }
              }

              & th, & td {
                &.range {
                  border-top: none;
                  border-bottom-color: transparent !important;
                  background-color: transparent;
                  min-width: 40px;
                }
              }
          }

          &.details {
              & th {
                  border-radius: 0;
              }
              & tbody {
                  & tr {
                      &:nth-child(2) {
                          & td, & th {
                              border-bottom: 3px solid #5b5c67;
                          }
                      }
                  }
              }
          }
        }
    }

    .files-loading {
        display: flex;
        column-gap: 10px;
        flex-wrap: nowrap;
        justify-content: space-between;

        & a {
            padding: 12px;
            color: #1A1B20;
            background-color: #FFAB2D;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            font: inherit;
            transition: background-color 300ms, color 300ms;
            text-decoration: none;
        }
    }

    .screen-shot-wrapper {
      width: fit-content;
    }

    .blue {
      color: $blue-middle;
    }

</style>
