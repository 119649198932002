<template>
    <transition name="fade" mode="in-out">
        <retro-test-page
                :retro-data="RETRO_DATA"
                :retro-deals="RETRO_DEALS"
                :retro-pnl="RETRO_PNL"
                :retro-settings="RETRO_SETTINGS"
                :active-offset="activeOffset"
                :offset-list="GET_OFFSET"
                @save="save"
                @changeTab="refreshData"
                @switch-offset="onSwitch"
        />
    </transition>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RetroTestPage from "../components/Pages/RetroTestPage";

    export default {
        name: 'RetroTest',
        components: {
            RetroTestPage,
        },
        data() {
            return {
                activeOffset: '3h',
                tabs: {
                    main: this.getRETRO_DATA,
                    details: this.getRetroPNL,
                    retroPnl: this.getRetroPNL,
                    retroDeals: this.getRetroDeals,
                },
                activeTab: "main"
            }
        },
        computed: {
            ...mapGetters(["RETRO_SETTINGS", "RETRO_DATA", "RETRO_DEALS", "RETRO_PNL", "GET_OFFSET"])
        },
        methods: {
            ...mapActions([
                "SET_LOADER",
                "getRETRO_SETTINGS",
                "setRETRO_SETTINGS",
                "getRETRO_DATA",
                "getRetroDeals",
                "getRetroPNL",
                "clearRetroData",
                "setREFRESH",
                "clearREFRESH"]),

            async onSwitch({ offset }) {
              if (offset) this.activeOffset = offset.value
            },

            async refreshData(tab = null) {
                tab ? this.activeTab = tab : ''
                await this.tabs[this.activeTab]()
                if (this.RETRO_DATA.start_tf) this.activeOffset = this.RETRO_DATA.start_tf.join()
                this.setREFRESH(this.refreshData)
            },
            async save(settings) {
                this.SET_LOADER(true)
                await this.setRETRO_SETTINGS({ settings, offset: this.activeOffset })
                this.clearRetroData()
            }
        },
        watch: {
          RETRO_DATA(newVal) {
            if (newVal.start_tf) {
              this.SET_LOADER(false)
            } else this.SET_LOADER(true)
          }
        },
        async mounted() {
            await this.getRETRO_SETTINGS(`${this.activeOffset}/`)
            await this.refreshData(this.activeTab)
        },
        destroyed() {
            this.clearREFRESH()
        },
    }
</script>
<style lang="scss">

</style>
